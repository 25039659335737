<template>
	<div id="app">
		<!-- NAVBAR -->
		<b-navbar type="dark" variant="dark" sticky>
			<b-navbar-brand href="#" variant="light">
				SpiderCite
			</b-navbar-brand>
			<b-navbar-nav class="ml-auto">
				<div class="mt-2 mr-3">
					<LensAttribution />
				</div>
				<!-- <b-button class="mr-3" @click="open('https://estech.shinyapps.io/citationchaser/')" variant="outline-light">
					<img height="25" src="@/assets/citationchasercustom.png" />
					citationchaser
				</b-button> -->
				<!--<b-button class="mr-2" @click="open('https://sr-accelerator.com/#/help/spidercite')" variant="info">Help</b-button>-->
				<b-button v-if="refs" @click="close" class="btn btn-danger">Clear</b-button>
			</b-navbar-nav>
		</b-navbar>

		<!-- Toolbar -->
		<div class="sticky-toolbar">
			<TheToolbar :user="user" />
		</div>

		<!-- Spacer to push down content (to prevent content from going underneath title/toolbar) -->
		<div class="spacer">
			&nbsp;
		</div>

		<!-- FILEUPLOAD -->
		<template v-if="!refs">
			<b-container class="file-container">
				<FileUpload />
				<!-- <b-button class="mt-3" variant="info" @click="loadDemo">Click here to use demo library</b-button> -->
			</b-container>
		</template>

		<!-- LOADING SPINNER -->
		<div v-if="isLoading" class="m-3 p-2 border border-secondary rounded">
			Import Progress:
			<b-progress :value="progress.parsing" :max="1" show-progress animated></b-progress>
			Lens Status:
			<div v-if="progress.parsing == 1">
				<b-spinner class="mr-3" />
				<strong>Querying Lens.org...</strong>
			</div>
		</div>

		<!-- MAIN AREA -->
		<template v-if="refs">
			<b-row class="m-3">
				<b-col cols="2">
					<b-form-group class="w-100">
						<b-form-radio-group
							class="w-100"
							v-model="selected"
							buttons
							button-variant="outline-primary"
							stacked
						>
							<template v-for="option in options">
								<b-form-radio :value="option.value" :key="option.value">
									<span class="float-left">{{ option.text }}</span>
									<span class="float-right">{{ "(" + calculateNumberReferences(option.value) + ")" }}</span>
								</b-form-radio>
							</template>
						</b-form-radio-group>
					</b-form-group>
				</b-col>
				<b-col>
					<RefsInputAccordion v-if="selected == 'input'" />
					<RefsOutputAccordion v-else />
				</b-col>
			</b-row>
		</template>

		<!-- Fixed footer which is used to display extra reference information -->
		<div v-if="showEditReference" id="fixed-footer" class="resizable">
			<div id="handle"></div>
			<EditReference :reference="editReference" />
		</div>

	</div>
</template>

<script>
import localforage from 'localforage'

import FileUpload from './components/FileUpload.vue'
import RefsInputAccordion from './components/RefsInputAccordion.vue'
import RefsOutputAccordion from './components/RefsOutputAccordion.vue'
import TheToolbar from './components/TheToolbar.vue'
import EditReference from './components/EditReference.vue';
import { mapGetters } from 'vuex'
import LensAttribution from './components/LensAttribution.vue'

export default {
	name: 'App',
	components: {
		FileUpload,
		RefsInputAccordion,
		RefsOutputAccordion,
		TheToolbar,
		EditReference,
		LensAttribution
	},
	computed: {
		...mapGetters('metadata', {
			title: 'getTitle'
		}),
		...mapGetters('references', {
			refs: 'getRefs'
		})
	},
	data() {
		return {
			user: "", // User ID
			isLoading: false,
			isEditTitle: false,
			showEditReference: false,
			editReference: {},
			progress: {
				parsing: 0
			},
			// Radio button stuff
			selected: 'input',
			options: [
				{ text: 'Input', value: 'input' },
				{ text: 'Output', value: 'output' }
			]
		}
	},
	async beforeCreate() {
		const storageMetadata = await localforage.getItem("metadata");
		const storageRefs = await localforage.getItem("refs");
		// Load values from local storage if they exist
		if (storageMetadata) {
			this.$store.commit('metadata/setMetadata', storageMetadata);
		}
		if (storageRefs) {
			this.$store.commit('references/setRefs', storageRefs);
		}
	},
	mounted() {
		// Get url parameter
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		this.user = urlParams.get('user');
		// Set loading status
		this.$root.$on('set-loading', isLoading => this.isLoading = isLoading);
		// Set progress
		this.$root.$on('set-progress', progressObj => {
			this.progress[progressObj.stage] = progressObj.progress
		});
		// Show edit footer
		this.$root.$on('show-edit', reference => {
			this.editReference = reference;
			this.showEditReference = true;
			// Resizable bottom fixed div
			this.$nextTick(function () {
				var handle = document.querySelector("#handle");
				var div = document.querySelector(".resizable");
				var divElm;
				var startOffset;

				handle.style.cursor = 'row-resize';
				handle.addEventListener('mousedown', function(e) {
					e.preventDefault();
					divElm = div;
					startOffset = div.offsetHeight - (window.innerHeight - e.pageY);
				})

				document.addEventListener('mousemove', function (e) {
					if (divElm) {
						if (startOffset + (window.innerHeight - e.pageY) < 200) {
							divElm.style.height = "200px";
						} else if (startOffset + (window.innerHeight - e.pageY) > (window.innerHeight * 0.9)) {
							divElm.style.height = window.innerHeight * 0.9 + 'px';
						} else {
							divElm.style.height = startOffset + (window.innerHeight - e.pageY) + 'px';
						}
					}
				}, { passive: true });

				document.addEventListener('mouseup', function () {
					divElm = undefined;
				});
			});
		});
		// Hide edit footer
		this.$root.$on('hide-edit', () => {
			this.editReference = {};
			this.showEditReference = false;
		});
	},
	methods: {
		calculateNumberReferences(key) {
			if (this.refs && this.refs[key]) {
				return Object.keys(this.refs[key]).reduce((total, subKey) => {
					return this.refs[key][subKey]
						? this.refs[key][subKey].length + total
						: total;
				}, 0)
			}
		},
		close() {
			// Reset store
			this.$store.dispatch('resetStore');
			// Clear localStorage
			localforage.clear();
		},
		open(url) {
			window.open(url, "_blank");
		},
		saveTitle() {
			this.$store.commit('metadata/setTitle', this.$refs['title-edit'].value);
			this.isEditTitle = false;
		},
	}
}
</script>

<style>
#app {
	min-height: 100vh;
	background-color: rgb(230, 230, 230);
}
.spacer {
	width: 100%;
	height: 40px;
}
.table-area {
	height: calc(100vh - 100px);
}
.sticky-toolbar {
	position: fixed;
	z-index: 104;
	width: 100%;
}
/* Footer css */
#fixed-footer {
	bottom: 0px !important;
	height: 50vh;
	overflow-y: scroll;
	position: fixed;
	z-index: 100;
	width: 100%;
}
/* Footer handle css */
#handle {
	position:sticky;
	top: 0px;
	left: 0px;
	width: calc(100% - 70px);
	height: 40px;
	z-index: 102;
	margin-bottom: -40px;
}
</style>
