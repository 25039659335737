<template>
	<div>
		<b-button-toolbar class="bg-secondary">
			<b-button-group>
				<!-- FILE -->
				<b-dropdown right text="File" class="custom-shadow">
					<input type="file" @change="callImportFile($event.target.files[0])" ref="importFile" style="display: none" accept=".xml, .csv, .json, .nbib, .ris, .txt, .tsv">
					<b-dropdown-item @click="$refs.importFile.click()">Import References</b-dropdown-item>
					<b-dropdown-item v-if="refs" @click="showModal(true)" v-b-modal.download-modal>Save</b-dropdown-item>
					<b-dropdown-divider v-if="refs"></b-dropdown-divider>
					<b-dropdown-item v-if="refs" @click="close">Clear</b-dropdown-item>
				</b-dropdown>
			</b-button-group>
		</b-button-toolbar>

		<!-- EXPORT MODAL -->
		<b-modal v-if="isShowModal" @close="showModal(false)" id="download-modal" title="Save" :hide-footer="true">
			<!-- Export progress bar -->
			<b-row class="mb-4" v-if="isLoading">
				<b-col>
					Progress:
					<b-progress :value="progress" show-progress animated></b-progress>
					<span v-if="progress == 0">Queueing...</span>
				</b-col>
			</b-row>
			<!-- All refs deduplicated -->
			<b-row class="mb-4">
				<b-col cols="7"><span>Backwards and Forwards Citation Search ({{allCitedRefsDeduplicated.length}})</span></b-col>
				<b-col><b-button :disabled="isLoading" variant="success" @click="fetchThenExport(allCitedRefsDeduplicated, 'snowballed-cited-and-referenced')">
					Save
				</b-button></b-col>
			</b-row>
			<!-- Warning if refs are limited -->
			<b-row v-if="isLimited">
				<b-col>
					<b-alert variant="warning" :show="isLimited === 'loggedInLimit'">
						To export more than 10,000 references, please <a href="https://sr-accelerator.com/#/contact" target="_blank">contact us</a>
					</b-alert>
				</b-col>
			</b-row>
			<!-- Estimated export time -->
			<b-row class="mb-4">
				<b-col><b>Estimated Time Remaining</b> (with no server load): {{Math.ceil(allCitedRefsDeduplicated.length / 1000) * 6}}s </b-col>
			</b-row>
			<!-- All other export options -->
			<hr />
			<!-- <b-row class="mb-4">
				<b-col cols="7"><span>All Cited Articles and References ({{allCitedRefs.length}})</span></b-col>
				<b-col><b-button :disabled="isLoading" @click="fetchThenExport(allCitedRefs, 'snowballed-cited-and-referenced')">
					Save
				</b-button></b-col>
			</b-row> -->
			<b-row class="mb-4">
				<b-col cols="7"><span>Backwards Citation Search ({{references.length}})</span></b-col>
				<b-col><b-button :disabled="isLoading" @click="fetchThenExport(references, 'snowballed-referenced')">
					Save
				</b-button></b-col>
			</b-row>
			<b-row class="mb-4">
				<b-col cols="7"><span>Forwards Citation Search ({{citations.length}})</span></b-col>
				<b-col><b-button :disabled="isLoading" @click="fetchThenExport(citations, 'snowballed-cited')">
					Save
				</b-button></b-col>
			</b-row>
			<hr />
			<b-row>
				<b-col cols="7"><span>Citations to be Manually Searched ({{refs.input.manual.length}})</span></b-col>
				<b-col><b-button :disabled="isLoading" @click="callExport(refs.input.manual, 'snowballed-manual')">
					Save
				</b-button></b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import importMixin from "../mixins/importMixin";
import exportMixin from "../mixins/exportMixin";

import { mapGetters } from "vuex";

export default {
	name: 'TheToolbar',
	mixins: [
		importMixin,
		exportMixin
	],
	props: {
		user: String
	},
	computed: {
		...mapGetters({
			refs: 'references/getRefs'
		}),
		allCitedRefsDeduplicated() {
			return this.trimArray([ ...new Set([...this.refs.output.references, ...this.refs.output.citations])]);
		},
		allCitedRefs() {
			return this.trimArray([
				...this.refs.output.citations,
				...this.refs.output.references
			]);
		},
		allRefs() {
			return this.trimArray([
				...this.refs.input.manual,
				...this.refs.input.loaded,
				...this.refs.output.citations,
				...this.refs.output.references
			]);
		},
		references() {
			return this.trimArray(this.refs.output.references)
		},
		citations() {
			return this.trimArray(this.refs.output.citations)
		},
		isLimited() {
			if (this.user && this.allCitedRefsDeduplicated.length === this.loggedInLimit) {
				return "loggedInLimit";
			} else if (!this.user &&  this.allCitedRefsDeduplicated.length === this.notLoggedInLimit) {
				return "notLoggedInLimit";
			} else {
				return false;
			}
		}
	},
	data() {
		return {
			isShowModal: false,
			notLoggedInLimit: 2000,
			loggedInLimit: 10000,
		}
	},
	methods: {
		callLoadFile(e) {
			this.loadFile(e);
			this.$refs.loadFile.value = null;
		},
		callImportFile(e) {
			this.importFile(e);
			this.$refs.importFile.value = null;
		},
		showModal(isShow) {
			this.isShowModal = isShow;
		},
		close() {
			this.$store.dispatch('resetStore');
		},
		// Hash string
		hashCode(string) {
			if (string && string.length > 0) {
				var hash = 0;
				for (var i = 0; i < string.length; i++) {
					var char = string.charCodeAt(i);
					hash = ((hash<<5)-hash)+char;
					hash = hash & hash; // Convert to 32bit integer
				}
				return hash;
			} else {
				return null;
			}
		},
		// Trim array to maximum length allowed by rules
		trimArray(array) {
			switch(this.hashCode(this.user)) {
				// Not logged in
				case null:
				case "":
					return array.slice(0, this.notLoggedInLimit);
				// Pre approved users
				case 261652084: // CF
				case -300308945: // JC
				case 1851164936: // MC
					return array;
				// Regular logged in user
				default:
					return array.slice(0, this.loggedInLimit);
			}
		}
	}
}
</script>

<style>
.dropdown.custom-shadow .dropdown-menu {
	box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.5) !important;
}
</style>
